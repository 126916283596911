import React from "react";
import Line from "./Line";
import { Container, Row, Col } from "react-bootstrap";
import CarouselGeneric from "./CarouselGeneric";
import ProductCard from "./ProductCard";
import Bike1 from "../images/products/Honda CD 70 2023.png";
import Bike2 from "../images/products/Honda CD 70 2023 Black.png";
import Bike3 from "../images/products/Honda CG 125 2023.png";
import Bike4 from "../images/products/Suzuki GD 110.jpg";
import Bike5 from "../images/products/Suzuki GD 110.png";
import Bike6 from "../images/products/Suzuki GD 110 Black.png";
import Bike7 from "../images/products/Suzuki GD 110 Red.png";
import Bike8 from "../images/products/Union Star 70 2022.png";
import Bike9 from "../images/products/Unique 70 2022.jpg";
import Bike10 from "../images/products/United 70 2022.jpg";
import Bike11 from "../images/products/United 100 2022.jpg";
import Bike12 from "../images/products/United 125 2022.jpg";

const MotorBikesCarousel = ({ getTranslatedText }) => {
  const items = [
    <ProductCard image={Bike1} title="Honda CD 70 2023" />,
    <ProductCard image={Bike2} title="Honda CD 70 2023 Black" />,
    <ProductCard image={Bike3} title="Honda CG 125 2023" />,
    <ProductCard image={Bike4} title="Suzuki GD 110" />,
    <ProductCard image={Bike5} title="Suzuki GD 110" />,
    <ProductCard image={Bike6} title="Suzuki GD 110 Black" />,
    <ProductCard image={Bike7} title="Suzuki GD 110 Red" />,
    <ProductCard image={Bike8} title="Union Star 70 2022" />,
    <ProductCard image={Bike9} title="Union Star 70 2022" />,
    <ProductCard image={Bike10} title="United 70 2022" />,
    <ProductCard image={Bike11} title="United 100 2022" />,
    <ProductCard image={Bike12} title="United 125 2022" />,
  ];
  return (
    <div className="product-carousel">
      <Container>
        <Row>
          <Col className="header">
            <Line type="large" />

            <h2 className="header-text">
              Get Your Favorite Motorbike on
            </h2>
            <h2 className="header-text" style={{color:'#FF5400'}}>
              Easy Monthly Installment
            </h2>
          </Col>
        </Row>

        <CarouselGeneric items={items} />
      </Container>
    </div>
  );
};

export default MotorBikesCarousel;
