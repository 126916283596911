import React from "react";
import Line from "./Line";
import { Container, Row, Col, Button } from "react-bootstrap";
import CarouselGeneric from "./CarouselGeneric";
import ProductCard from "./ProductCard";
import Bike6 from "../images/products/Suzuki GD 110.png";



const MotorBikesCarousel = ({ getTranslatedText }) => {
  const items = [<ProductCard image={Bike6} title="Suzuki GD 110" />];

  return (
    <div className="product-preview">
      <Container>
        <Row>
          <Col lg={6} xl={6} className="content">
            <CarouselGeneric items={items} />
          </Col>

          <Col lg={6} xl={6} className="column-content">
            <h1>RS. 174,500</h1>
            <div className="inner">
              <h2>Registration</h2>
              <div className="btn-content">
                <Button className="btn-colored" style={{ marginRight: "20px" }}>
                  None
                </Button>
                <Button className="btn-border">2022 Registration</Button>
              </div>
            </div>
            <div className="inner">
              <h2>Takaful</h2>
              <div className="btn-content">
                <Button className="btn-border" style={{ marginRight: "20px" }}>
                  None
                </Button>
                <Button className="btn-colored">
                  UIC 3T Takaful 100% cover
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MotorBikesCarousel;
