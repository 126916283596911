import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import ProductBanner from "../../components/ProductBanner";
import WhatsappChat from "../../components/WhatsappChat";
import Bike6 from "../../images/products/Suzuki GD 110.png";
import MotorBikesCarousel from "../../components/MotorBikesCarousel";
import { localStorageKeys } from "../../utility/localstorage";
import { getTranslatedText } from "../../utility/constant";
import ProductForm from "../../components/ProductForm";

const MotorBikePageIndex = ({}) => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <ProductBanner
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
        headlineOne="GET MOTORBIKES "
        headlineThree="ON INSTALLMENT"
        text="Get your favorite bike on easy monthly installments through fast, fair, and hassle-free Shariah-compliant."
        buttonLabel={getTranslatedText(
          myCustomAppState.language,
          "Banner DownloadButton"
        )}
        buttonLabel2="Open Calculator"
        rightColImage={Bike6}
      />

      <ProductForm
        headingOne="MOTORBIKES"
        headingTwo="On Installments "
        bodyText={
          <>
            Honda, Suzuki, Unique, United, or Union Star you name it, we have
            got it! With CreditPer, you can get your favorite bike on easy
            monthly installment through fast, fair, and hassle-free
            Shariah-Compliant financing. Get the product that you have been
            looking for and apply for the installment plan that suits you best.
            <br />
            <br />
            Our objective is to help people acquire bikes easily to elevate
            their standard of living. With the lowest profit rate and lowest
            down payment, we can help you get the bike you've been dreaming of.
            CreditPer strives to empower its customers by providing real-time
            digital solutions to increase financial inclusion in Pakistan.
          </>
        }
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />

      <MotorBikesCarousel
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />

      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default MotorBikePageIndex;
