import React from "react";
import { Image } from "react-bootstrap";

const ProductCard = (props) => {
  const { image, title, price, type } = props;

  return (
    <div className="product-card">
      <Image src={image} fluid={true} className="image" />
      <div className="wrapper">
        <p className="title">
          {title}
          <span className="type"> &nbsp;{type}</span>
        </p>
        <p className="price">{price}</p>
      </div>
    </div>
  );
};

export default ProductCard;
