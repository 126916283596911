import React from "react";
import { Container } from "react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const responsive = {
  0: { items: 1 },
  768: { items: 2 },
  992: { items: 3 },
  1200: { items: 4 },
};

const CarouselGeneric = ({ items, imagePreview }) => {
  const renderNextButton = ({ isDisabled }) => {
    return (
      <ArrowForwardIosIcon
        className="carousel-right"
        style={{
          position: "absolute",
          right: 0,
          top: 100,
          width: "30px",
          height: "30px",
          color: "#007290",
          padding:'0 !important',
        }}
      />
    );
  };

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <ArrowBackIosIcon
        style={{
          position: "absolute",
          left: 0,
          top: 100,
          width: "30px",
          height: "30px",
          color: "#007290",
          padding:'0 !important',
        }}
      />
    );
  };

  return (
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          animationDuration={500}
          disableDotsControls={true}
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
          playButtonEnabled={true}
        />
  );
};

export default CarouselGeneric;
