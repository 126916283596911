import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "./Button";
import CalculatorModal from "./CalculatorModal";
import ProductPreview from "./ProductPreview";
import HeroForm from "../components/HeroForm";

const ProductForm = ({
  getTranslatedText,
  myCustomAppState,
  setMyCustomAppState,
  headingOne,
  headingTwo,
  bodyText,
}) => {
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
    // setIsShown(true)
  };

  return (
    <div>
       <section className="product-form">
      <Container>
        <Row>
          <Col lg={6} xl={6} className="content">
            <h1 className="heading1">{headingOne} </h1>
            <h1 className="heading2">{headingTwo}</h1>

            <p className="content-text">{bodyText}</p>
            <Button label="Open Calculator" onClick={handleClick} />
          </Col>
          <Col lg={6} xl={6} className="column-content">
            <HeroForm
              myCustomAppState={myCustomAppState}
              setMyCustomAppState={setMyCustomAppState}
              getTranslatedText={getTranslatedText}
            />
          </Col>
        </Row>
      </Container>
      
    </section>
    {isShown && <ProductPreview />}
    {isShown && <CalculatorModal />}
    </div>
   
  );
};

export default ProductForm;
