import React, { useState } from "react";
import Line from "./Line";
import { Container, Row, Col } from "react-bootstrap";

const CalculatorModal = ({}) => {
  const [isColorChange, setIsColorChange] = useState(true);
  const [selectedMonths, setSelectedMonths] = useState("6 Months");

  const changeColor = (months) => {
    setIsColorChange((current) => !current);
    setSelectedMonths(months);
    console.log(selectedMonths, "dataa");
  };

  const changeCost = () => {
    if (selectedMonths == "3 Months") {
      return 229000;
    }
    if (selectedMonths == "6 Months") {
      return 229000;
    }
    if (selectedMonths == "12 Months") {
      return 229000;
    }
    if (selectedMonths == "18 Months") {
      return 229000;
    }
    if (selectedMonths == "24 Months") {
      return 229000;
    }
  };

  const changeReg = () => {
    if (selectedMonths == "3 Months") {
      return 7490;
    }
    if (selectedMonths == "6 Months") {
      return 7490;
    }
    if (selectedMonths == "12 Months") {
      return 7490;
    }
    if (selectedMonths == "18 Months") {
      return 7490;
    }
    if (selectedMonths == "24 Months") {
      return 7490;
    }
  };

  const changeTakaful = () => {
    if (selectedMonths == "3 Months") {
      return 28379;
    }
    if (selectedMonths == "6 Months") {
      return 28379;
    }
    if (selectedMonths == "12 Months") {
      return 28379;
    }
    if (selectedMonths == "18 Months") {
      return 28379;
    }
    if (selectedMonths == "24 Months") {
      return 28379;
    }
  };

  const changeCashPrice = () => {
    if (selectedMonths == "3 Months") {
      return 264869;
    }
    if (selectedMonths == "6 Months") {
      return 264869;
    }
    if (selectedMonths == "12 Months") {
      return 264869;
    }
    if (selectedMonths == "18 Months") {
      return 264869;
    }
    if (selectedMonths == "24 Months") {
      return 264869;
    }
  };

  const changeAdvance = () => {
    if (selectedMonths == "3 Months") {
      return 22900;
    }
    if (selectedMonths == "6 Months") {
      return 22900;
    }
    if (selectedMonths == "12 Months") {
      return 22900;
    }
    if (selectedMonths == "18 Months") {
      return 22900;
    }
    if (selectedMonths == "24 Months") {
      return 22900;
    }
  };

  const changeMonRental = () => {
    if (selectedMonths == "3 Months") {
      return 85937;
    }
    if (selectedMonths == "6 Months") {
      return 45236;
    }
    if (selectedMonths == "12 Months") {
      return 24998;
    }
    if (selectedMonths == "18 Months") {
      return 19326;
    }
    if (selectedMonths == "24 Months") {
      return 16705;
    }
  };

  const changeTimeCost = () => {
    if (selectedMonths == "3 Months") {
      return 284298;
    }
    if (selectedMonths == "6 Months") {
      return 297901;
    }
    if (selectedMonths == "12 Months") {
      return 326461;
    }
    if (selectedMonths == "18 Months") {
      return 375775;
    }
    if (selectedMonths == "24 Months") {
      return 430245;
    }
  };

  const changeRental = () => {
    if (selectedMonths == "3 Months") {
      return 0;
    }
    if (selectedMonths == "6 Months") {
      return 0;
    }
    if (selectedMonths == "12 Months") {
      return 0;
    }
    if (selectedMonths == "18 Months") {
      return 0;
    }
    if (selectedMonths == "24 Months") {
      return 0;
    }
  };

  const changeDiffernce = () => {
    if (selectedMonths == "3 Months") {
      return 0;
    }
    if (selectedMonths == "6 Months") {
      return 0;
    }
    if (selectedMonths == "12 Months") {
      return 0;
    }
    if (selectedMonths == "18 Months") {
      return 0;
    }
    if (selectedMonths == "24 Months") {
      return 0;
    }
  };

  const firstSection = [
    {
      title: "Cost",
      price: `Rs. ${changeCost()}`,
    },
    {
      title: "Registration",
      price: `Rs. ${changeReg()}`,
    },
    {
      title: "Takaful (%)",
      price: `% ${changeTakaful()}`,
    },
  ];

  const secondSection = [
    {
      title: "Cash Price",
      price: `Rs. ${changeCashPrice()}`,
    },
    {
      title: "Advance",
      price: `Rs. ${changeAdvance()}`,
    },
  ];

  const months = [
    {
      month: "3 Months",
    },
    {
      month: "6 Months",
    },
    {
      month: "12 Months",
    },
    {
      month: "18 Months",
    },
    {
      month: "24 Months",
    },
  ];

  const thirdSection = [
    {
      title: "Advance Payment",
      price: `Rs. ${changeAdvance()}`,
    },
    {
      title: "Monthly Rental",
      price: `Rs. ${changeMonRental()}`,
    },
    {
      title: "One Time Cost",
      price: `Rs. ${changeTimeCost()}`,
    },
    {
      title: "Total Rental",
      price: `Rs. -`,
    },
    {
      title: "Price Difference",
      price: `Rs. -`,
    },
  ];

  return (
    <div className="calculator-modal" id="#calculator">
      <Container>
        <Row>
          <Col className="header">
            <Line type="large" />
            <h2 className="header-text">Monthly Rental Calculator</h2>
          </Col>
        </Row>

        <Row>
          {firstSection.map((data) => (
            <Col lg={4} xl={4} className="inputfields my-3">
              <div className="colored-block">{data.title}</div>
              <div className="transparent-block">{data.price}</div>
            </Col>
          ))}
        </Row>

        <Row>
          {secondSection.map((data) => (
            <Col lg={6} xl={6} className="inputfields my-3">
              <div className="colored-block">{data.title}</div>
              <div className="transparent-block">{data.price}</div>
            </Col>
          ))}
        </Row>

        <div className="button-secttion" >
          {months.map((data) => (
            
              <button
                className="months-block"
                style={{
                  backgroundColor:
                    selectedMonths === data.month ? "#007290" : "",
                  color: selectedMonths === data.month ? "white" : "",
                }}
                onClick={() => changeColor(data.month)}
              >
                {data.month}
              </button>
          ))}
        </div>

        <Row>
          {thirdSection.map((data) => (
            <Col lg={12} xl={12} className="inputfields my-3">
              <div className="colored-block" style={{ width: "35%" }}>
                {data.title}
              </div>
              <div className="transparent-block" style={{ width: "65%" }}>
                {data.price}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CalculatorModal;
